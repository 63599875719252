/**
 *
 * DrawerMenu
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { withTheme } from '@mui/styles';

import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import MaterialLink from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ProfileIcon from '@mui/icons-material/AccountCircle';
import DocumentsIcon from '@mui/icons-material/InsertDriveFile';
import CalendarIcon from '@mui/icons-material/PermContactCalendar';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ExperiencesIcon from '@mui/icons-material/Chat';
import TurnReportIcon from '@mui/icons-material/Assignment';
import RouteIcon from '@mui/icons-material/TransferWithinAStation';
import DepotIcon from '@mui/icons-material/AccountBalance';
import CircuitIcon from '@mui/icons-material/DonutLarge';
import CongregationIcon from '@mui/icons-material/LocationCity';
import UsersIcon from '@mui/icons-material/Group';
import NewsIcon from '@mui/icons-material/Comment';
import ImpressumIcon from '@mui/icons-material/InfoOutlined';
import PrivacyIcon from '@mui/icons-material/Lock';
import TermsIcon from '@mui/icons-material/Description';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';

import HasRole, { roles } from '~/containers/HasRole';
import CurrentAppVersion from '~/containers/CurrentAppVersion';
import ExpandableListItem from '~/components/ExpandableListItem';
import withSettings from '~/containers/WithSettings';
import Spacings from '~/modules/ui-kit/spacings';
import * as selectors from './selectors';
import * as actions from './actions';
import styles from './styles.module.css';
import messages from './messages';

export const publicItems = (isExperiencesFeatureEnabled) =>
  [
    { route: '/home', label: messages.dashboard, icon: DashboardIcon },
    { route: '/application', label: messages.calendar, icon: CalendarIcon },
    {
      route: '/availability',
      label: messages.availability,
      icon: EventAvailableIcon,
    },
    isExperiencesFeatureEnabled
      ? {
          route: '/experiences',
          label: messages.experiences,
          icon: ExperiencesIcon,
        }
      : undefined,
    { route: '/user-profile', label: messages.userProfile, icon: ProfileIcon },
    { route: '/documents', label: messages.documents, icon: DocumentsIcon },
  ].filter(Boolean);

export const administrationItems = (isExperiencesFeatureEnabled) =>
  [
    { route: '/weekplan', label: messages.weekplan, icon: CalendarIcon },
    {
      route: '/turn-reports',
      label: messages.turnReports,
      icon: TurnReportIcon,
    },
    { route: '/routes', label: messages.routeList, icon: RouteIcon },
    { route: '/depots', label: messages.depotManagement, icon: DepotIcon },
    {
      route: '/circuits',
      label: messages.circuitManagement,
      icon: CircuitIcon,
    },
    {
      route: '/congregations',
      label: messages.congregationManagement,
      icon: CongregationIcon,
    },
    { route: '/users', label: messages.userManagement, icon: UsersIcon },
    {
      route: '/news-entries',
      label: messages.newsEntryManagement,
      icon: NewsIcon,
    },
    { route: '/reports/branch', label: messages.reports, icon: AssessmentIcon },
    isExperiencesFeatureEnabled
      ? {
          route: '/experienceList',
          label: messages.experiences,
          icon: ExperiencesIcon,
        }
      : undefined,
    {
      route: '/settings',
      label: messages.settings,
      icon: SettingsIcon,
      subItems: [
        {
          route: '/settings/planning',
          label: messages.planningSettings,
        },
        {
          route: '/settings/planningSchedule',
          label: messages.planningScheduleSettings,
        },
        {
          route: '/settings/news',
          label: messages.newsSettings,
        },
        {
          route: '/settings/reporting',
          label: messages.reportingSettings,
        },
        {
          route: '/settings/environment',
          label: messages.environmentSettings,
        },
        {
          route: '/settings/smtp',
          label: messages.smtpSettings,
        },
        {
          route: '/settings/masterData',
          label: messages.masterdataSettings,
        },
      ],
    },
  ].filter(Boolean);

export const legalItems = [
  {
    key: 'impressum',
    route: '/impressum',
    label: messages.impressum,
    icon: ImpressumIcon,
  },
  { key: 'terms', route: '/terms', label: messages.terms, icon: TermsIcon },
  {
    key: 'privacyPolicy',
    route: '/privacy',
    label: messages.privacyPolicy,
    icon: PrivacyIcon,
  },
];

export const DrawerMenu = (props) => {
  const history = useHistory();
  const location = useLocation();

  const closeDrawerMenu = () => {
    props.setDrawerMenuState({
      isDrawerMenuOpen: false,
    });
  };
  const navigateToRoute = (route) => {
    closeDrawerMenu();
    history.push(route);
  };
  const { isOpen, setDrawerMenuState } = props;
  const isExperiencesFeatureEnabled =
    props.settings && props.settings.reporting
      ? props.settings.reporting.isExperiencesFeatureEnabled
      : false;
  return (
    <Drawer
      data-test="drawer-button"
      open={props.forceOpen || isOpen}
      onClose={() => setDrawerMenuState({ isDrawerMenuOpen: false })}
      variant={props.variant}
    >
      <Spacings.Inset>
        <MaterialLink
          variant="h6"
          color="textSecondary"
          component={Link}
          to="/"
          underline="none"
        >
          {!props.isFetchingSettings
            ? props.settings.environment.tenantName
            : null}
        </MaterialLink>
      </Spacings.Inset>
      <Divider />
      <List style={{ width: 300, overflowY: 'auto' }}>
        {publicItems(isExperiencesFeatureEnabled).map((item) => (
          <ListItem
            data-test-type="drawer-menu-button"
            key={item.label.id}
            onClick={() => navigateToRoute(item.route)}
            button
            selected={Boolean(
              matchPath(location.pathname, {
                path: item.route,
                exact: item.route === '/',
              })
            )}
          >
            <ListItemIcon>
              <item.icon />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage {...item.label} />} />
          </ListItem>
        ))}
        <HasRole roles={[roles.administrator]}>
          <Divider />
          <ListSubheader disableSticky>
            <FormattedMessage {...messages.administration} />
          </ListSubheader>
          {administrationItems(isExperiencesFeatureEnabled).map((item) =>
            item.subItems ? (
              <ExpandableListItem
                data-test-type="drawer-menu-button"
                key={item.label.id}
                icon={<item.icon />}
                primaryLabel={<FormattedMessage {...item.label} />}
                open={Boolean(
                  matchPath(location.pathname, {
                    path: item.route,
                  })
                )}
              >
                {item.subItems.map((subItem) => (
                  <ListItem
                    data-test-type="drawer-menu-button"
                    key={subItem.label.id}
                    onClick={() => navigateToRoute(subItem.route)}
                    button
                    selected={Boolean(
                      matchPath(location.pathname, {
                        path: subItem.route,
                      })
                    )}
                  >
                    <ListItemText
                      primary={<FormattedMessage {...subItem.label} />}
                    />
                  </ListItem>
                ))}
              </ExpandableListItem>
            ) : (
              <ListItem
                data-test-type="drawer-menu-button"
                key={item.label.id}
                onClick={() => navigateToRoute(item.route)}
                button
                selected={Boolean(
                  matchPath(location.pathname, {
                    path: item.route,
                  })
                )}
              >
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage {...item.label} />} />
              </ListItem>
            )
          )}
        </HasRole>
        <Divider />
        {legalItems.map((item) => (
          <ListItem
            data-test={item.key}
            data-test-type="drawer-menu-button"
            key={item.label.id}
            onClick={() => navigateToRoute(item.route)}
            button
          >
            <ListItemIcon>
              <item.icon />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage {...item.label} />} />
          </ListItem>
        ))}
        <ListItem
          href="/Identity/Account/Logout"
          data-test="logout-button"
          data-test-type="drawer-menu-button"
          component="a"
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={<FormattedMessage {...messages.logout} />} />
        </ListItem>
      </List>
      <div className={styles['current-app-version']}>
        <FormattedMessage
          {...messages.appVersion}
          values={{ version: <CurrentAppVersion /> }}
        />
      </div>
    </Drawer>
  );
};

DrawerMenu.propTypes = {
  variant: PropTypes.string,
  forceOpen: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  setDrawerMenuState: PropTypes.func.isRequired,
  isFetchingSettings: PropTypes.bool.isRequired,
  settings: PropTypes.shape({
    environment: PropTypes.shape({
      tenantName: PropTypes.string.isRequired,
    }),
    reporting: PropTypes.shape({
      isExperiencesFeatureEnabled: PropTypes.bool.isRequired,
    }),
  }),
  /* mui theme */
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = createStructuredSelector({
  isOpen: selectors.selectIsDrawerMenuOpen(),
});

export default compose(
  withTheme,
  withSettings,
  connect(mapStateToProps, {
    setDrawerMenuState: actions.setDrawerMenuState,
  })
)(DrawerMenu);
