import React, { Suspense, lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';
import { useEmergencyModeCurrent } from '~/hooks/api/useEmergencyModeCurrent';

const AsyncHomePage = lazy(() => import('~/containers/HomePage'));

const EmergencyStatusRedirect = () => {
  const { data, isLoading } = useEmergencyModeCurrent();
  if (isLoading) {
    return <ApplicationSkeleton />;
  }
  return data ? <Redirect to="/emergency-status" /> : <Redirect to="/home" />;
};

const homepageRoutes = [
  <Route path="/" exact>
    <EmergencyStatusRedirect />
  </Route>,
  <Route path="/home" exact>
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncHomePage />
    </Suspense>
  </Route>,
];

export default homepageRoutes;
